.hero_app__gallery {
  flex-direction: row;

  background: var(--color-black);
  padding: 4rem 0 4rem 6rem;
}

.hero_app__gallery-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  min-width: 500px;
  padding-right: 2rem;
}

.hero_app__gallery-content button {
  margin-top: 1rem;
}

.hero_app__gallery-images {
  flex: 1;
  display: flex;
  flex-direction: row;
  max-width: 50%;
  position: relative;
}

.hero_app__gallery-images_container {
  display: flex;
  flex-direction: row;
  width: max-content;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hero_app__gallery-images_container::-webkit-scrollbar {
  display: none;
}

.hero_app__gallery-images_card {
  position: relative;
  min-width: 301px;
  height: 447px;
  margin-right: 2rem;
}

.gallery__image-icon {
  position: absolute;
  color: #fff;
  font-size: 2rem;
  opacity: 0;
  transition: 0.5s ease;
  cursor: pointer;
}

.hero_app__gallery-images_card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  transition: 0.5s ease;
}

.hero_app__gallery-images_card:hover img {
  opacity: 0.35;
}

.hero_app__gallery-images_card:hover .gallery__image-icon {
  opacity: 1;
}

.hero_app__gallery-images_arrows {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 1rem;
  position: absolute;
  bottom: 5%;
}

.gallery__arrow-icon {
  color: var(--color-golden);
  font-size: 2rem;
  cursor: pointer;
  background: var(--color-black);
  border-radius: 5px;
}

.gallery__arrow-icon:hover {
  color: var(--color-white);
}

.hero__button {
  background-color: var(--color-golden);
  color: var(--color-white);
  font-family: var(--font-alt);
  font-weight: 700;
  /* letter-spacing: 0.04em;
  line-height: 28px; */
  font-size: 16px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 100px;
  border: 1px solid var(--color-white);
  padding: 1.2em 1.2em;
  transition: color 0.5s, background-color 0.5s, border-color 0.5s;
}

.hero__button:hover {
  color: var(--color-black); /* Change text color on hover */
  background-color: var(--color-white);
  border-color: var(--color-golden);
  font-weight: bold; /* Make text bold on hover */
}

.two-column-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr)); /* Two columns */
  justify-content: space-between;
  /* padding: 0 10% 0 10%; */
  gap: 2%;
  margin-top: 5%;
  margin-bottom: 2%;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  text-align: center;
  padding: 3% 3% 3% 0%;
}

@media screen and (min-width: 2000px) {
  .hero_app__gallery-content button {
    margin-top: 2rem;
  }

  .hero_app__gallery-content {
    min-width: 1000px;
    padding-right: 4rem;
  }

  .hero_app__gallery-images_card {
    min-width: 400px;
    height: 547px;
  }

}

@media screen and (max-width: 1150px) {
  .hero_app__gallery {
    flex-direction: column;
  }

  .hero_app__gallery-images {
    max-width: 100%;
    margin: 5rem 0;
  }
}

@media screen and (max-width: 850px) {
  .hero_app__gallery {
    padding: 1rem 0 0rem 2rem;
  }

  .two-column-container {
    grid-template-columns: repeat(1, minmax(0, 1fr)); /* Two columns */
    padding: 0 10% 0 10%;
    gap: 0%;
    margin-bottom: 10%;
  }

  .column {
    align-items: center;
    padding: 3%;
  }

  .hero__button {
    width: 85vw;
    padding: 0.6em 1.2em;
  }
}

@media screen and (max-width: 650px) {
  /* .hero_app__gallery {
    padding: 4rem 0 4rem 2rem;
  } */

  .hero_app__gallery-content {
    min-width: 100%;
  }

  .hero_app__gallery-images_card {
    min-width: 240px;
    height: 320px;
  }

}
